<template>
  <v-card class="pa-4">
    <div class="d-flex justify-space-between">
      <v-card-title class="title pa-0">Dodaj korisnika</v-card-title>
      <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <v-form class="mt-4">
      <v-row>
        <v-col cols="6">
          <v-text-field
              outlined
              dense
              label="Ime"
              v-model="userForm.firstName"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              label="Prezime"
              v-model="userForm.lastName"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              label="Prezime"
              v-model="userForm.emailAddress"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="pa-0">
      <v-col class="text-right pa-0">

        <v-btn @click="$emit('onSave', userForm)" color="primary" depressed>Spremi</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script>

export default {
  name: 'AddUser',
  data: () => ({
    userForm: {}
  }),
}
</script>
