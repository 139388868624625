<template>
  <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="12"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
      itemsPerPageOptions: [12]}
    "
      @click:row="selectItem"
      :server-items-length="pagingData.totalItems"
      style="border: 1px solid #dedede"
  >
  </v-data-table>
</template>
<script>
export default {
  props: ['data', 'pagingData', 'loading'],
  data () {
    return {
      options: {},
      initializing: false,
      headers: [
        {
          text: 'ID ',
          align: 'start',
          value: 'id',
        },
        { text: 'Ime', value: 'firstName', align: 'start' },
        { text: 'Prezime', value: 'lastName', align: 'start' },
        { text: 'E-Mail Adresa', value: 'emailAddress' },
        { text: 'Uloga', value: 'role' },
      ],
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('onSelectUser', item)
    }
  },
  watch: {
    options: {
      handler(val) {
        if (!this.initializing) {
          this.initializing = true;
        } else {
          this.$emit('onPaginate', val)
        }
      },
      deep: true
    }
  }
}
</script>
