<template>
  <div>
    <v-card class="pa-4">
      <v-card-text class="pa-0">
        <v-row>
          <v-col cols="12">
            <span class=" subtitle-1 font-weight-bold">Informacije</span>
          </v-col>
        </v-row>
        <v-row v-if="user.firstName">
          <v-col cols="6">
            <span class="subtitle-2">Ime</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ user.firstName }}
          </v-col>
        </v-row>
        <v-row v-if="user.lastName">
          <v-col cols="6">
            <span class="subtitle-2">Prezime</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ user.lastName }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="subtitle-2">Email Address</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ user.emailAddress }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="subtitle-2">Uloga</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ user.role }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-0 mt-8">
        <v-col class="text-right pa-0">

          <v-btn color="gray" depressed @click="$emit('onClose')">Zatvori</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "User"
}
</script>

<style scoped>

</style>
