<template>
  <v-container fluid>
    <v-dialog
        v-model="userOpened"
        width="1080px"
    >
      <User :member="selectedUser" @onClose="userOpened = false" />
    </v-dialog>
    <v-dialog
        v-model="addOpened"
        width="1080px"
    >
      <AddUser @onSave="addUser" @onClose="addOpened = false" />
    </v-dialog>
    <v-row class="fill-height">
      <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-0 overflow-y-auto">
        <Filters @onApply="applyFilters" />
      </v-col>
      <v-divider
          class="d-none d-md-flex"
          vertical/>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10" class="ml-n3 py-0 pr-0 fill-height">

        <TableView :data="users" :pagingData="pagingData" :loading="loading" @onPaginate="handlePaginate" @onSelectUser="selectUser"/>
      </v-col>
    </v-row>
    <v-btn fixed fab bottom right color="primary" @click="addOpened = true"><v-icon>mdi-plus</v-icon></v-btn>
  </v-container>
</template>

<script>
import TableView from "../components/users/TableVIew";
import Filters from "../components/users/Filters";
import User from "../components/users/User";
import AddUser from "../components/users/AddUser";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "Users",
  components: {
    TableView,
    Filters,
    User,
    AddUser
  },
  data: () => ({
    userOpened: false,
    addOpened: false
  }),
  computed: {
    ...mapGetters({
      "users": "user/users",
      "pagingData": "user/pagingData",
      "loading": "user/loadingUsers",
      "selectedUser": "user/selectedUser",
      "token": "user/token",
    })
  },

  mounted() {
    if (!this.token) {
      this.$router.replace("/login")
    } else {
      this.getSelf().catch(err => {
        if (err.response.status === 401) {
          this.router.replace("/login");
        }
      });
    }
    this.getUsers({
      page: 1
    });
  },
  methods: {
    ...mapActions({
      "addAlert": "global/addAlert",
      "getUsers": "users/get",
      "selectSpecificUser": "users/select",
      "addNewUser": "users/addUser",
      "getSelf": "user/getSelf"
    }),
    selectMember(user) {
      this.selectSpecificUser(user);
      this.userOpened = true;
    },
    addUser(user) {
      this.addNewUser(user).then(() => {
        this.addOpened = false;
        this.addAlert({
          type: 'success',
          message: `Član ${user.firstName} ${user.lastName} uspješno pozvan.`
        });
      }).catch(err => {
        console.log(err.response.data);
      });
    },
    nextPage() {

    },
    prevPage() {

    },
    handlePaginate(val) {
      let { sortBy, sortDesc, page } = val;
      let query = {
        ...this.$store.getters["users/query"],
        page: page
      };
      let orders = [];
      if (sortBy.length > 0){
        sortBy.map((sort, i) => {
          let specificOrder = []
          specificOrder.push(sort);
          specificOrder.push(sortDesc[i] ? "DESC" : "ASC")
          orders.push(specificOrder)
        })
      }
      if (orders.length > 0) {
        query.order = `[${JSON.stringify(orders)}]`
      }
      this.getUsers(query);
    },
    applyFilters(filters) {
      console.log(filters);
    },
  },
};
</script>

<style></style>
