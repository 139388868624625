<template>
  <v-container fluid>
    <p class="title-1 font-weight-medium mb-4">Filteri</p>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          v-model="filters.firstName"
          placeholder="Ime"
          class="rounded-t-lg rounded-b-lg"
          append-icon="mdi-close"
          @click:append="clearField('firstName')"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            outlined
            dense
            v-model="filters.lastName"
            placeholder="Prezime"
            class="rounded-t-lg rounded-b-lg"
            append-icon="mdi-close"
            @click:append="clearField('lastName')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-text-field
        outlined
        dense
        v-model="filters.email"
        placeholder="E-Mail"
        class="rounded-t-lg rounded-b-lg"
        append-icon="mdi-close"
        @click:append="clearField('email')"
    ></v-text-field>
    <v-select
        placeholder="Korisnička uloga"
        outlined
        dense
        :items="roles"
        item-text="name"
        item-value="value"
        append-icon="mdi-close"
        @click:append="clearField('role')"
        v-model="filters.role"
        class="rounded-t-lg rounded-b-lg"></v-select>
    <v-btn color="primary" block @click="applyFilters">Primijeni</v-btn>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// TODO: stao sam na toggle filterima
export default {
  computed: {
    ...mapGetters({"sections": "section/sections", "statuses": "members/statuses"})
  },
  data: () => ({
    roles: [{
      "name": "Administrator",
      "value": "role"
    },
    {
      "name": "Lovnik",
      "value": "leader"
    }],
    filters: {
      firstName: null,
      lastName: null,
      email: null,
      role: null,
    },
  }),
  methods: {
    ...mapActions({"getMembers": "members/get"}),
    clearField(field) {
      this.filters[field] = null;
    },
    applyFilters() {
      var parameterizedFilters = {}
      if (this.filters.firstName) {
        parameterizedFilters["firstName"] = {
          "like": this.filters.firstName
        }
      }
      if (this.filters.lastName) {
        parameterizedFilters["lastName"] = {
          "like": this.filters.lastName
        }
      }
      if (this.filters.cardNumber) {
        parameterizedFilters["cardNumber"] = {
          "like": this.filters.cardNumber
        }
      }
      if (this.filters.memberStatus) {
        parameterizedFilters["memberStatus"] = this.filters.memberStatus
      }
      if (this.filters.sectionId) {
        parameterizedFilters["sectionId"] = this.filters.sectionId
      }
      this.getMembers({
        page: 1,
        where: parameterizedFilters});
    }
  }
};
</script>

<style></style>
